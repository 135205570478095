import { CampaignStatusEnum } from '../campaigns.model';
import Chip from '@mui/material/Chip'

export const StatusComponent = ({ status }: { status: CampaignStatusEnum }) => {
  switch (status) {
    case CampaignStatusEnum.CREATED:
      return <Chip size='small' label='Created' color='default' />
    case CampaignStatusEnum.DELETED:
      return <Chip size='small' label='Deleted' color='error' />
    case CampaignStatusEnum.COMPLETED:
      return <Chip size='small' label='Completed' color='success' />
    case CampaignStatusEnum.INPROGRESS:
      return <Chip size='small' label='In progress' color='primary' />
    case CampaignStatusEnum.STOPPED:
      return <Chip size='small' label='Stopped' color='warning' />
    case CampaignStatusEnum.VERIFIED:
      return <Chip size='small' label='Verified' color='default' />
    default:
      throw new Error('unknown')
  }
}