export type CampaignsQueryParamsType = {
  pageSize: number
  page: number
  sort: 'asc' | 'desc' | null | undefined
  field: string
  total?: number
}

export enum CampaignStatusEnum {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  VERIFIED = 'VERIFIED',
  INPROGRESS = 'INPROGRESS',
  STOPPED = 'STOPPED',
  COMPLETED = 'COMPLETED'
}

export type CampaignsListResponseItemType = {
  id: number
  title: string
  status: CampaignStatusEnum
  createdAt: Date
}

export type CampaignsListResponseType = {
  items: CampaignsListResponseItemType[]
}

export type CampaignsCreateRequestType = {
  title: string
}

export type CampaignsCreateResponseType = {
  id: number
}
