import styled from '@emotion/styled'

const Container = styled.div`
  margin-top: 1.5rem;

  button:not(:first-of-type) {
    margin-left: 0.5rem;
  }
`

export const ButtonsContainer = ({ children }: any) => {
  return (
    <Container>
      {children}
    </Container>
  )
}
