import { PagesCreatePage } from './pages/PagesCreartePage';
import { PagesDetailsPage } from './pages/PagesDetailsPage';
import { PagesEditPage } from './pages/PagesEditPage';
import { PagesIndexPage } from './pages/PagesIndexPage';

export const pagesRoutes = [
  {
    key: 'pages',
    path: '/pages',
    component: PagesIndexPage
  },
  {
    key: 'pages_id',
    path: '/pages/:id',
    component: PagesDetailsPage
  },
  {
    key: 'pages_add',
    path: '/pages/add',
    component: PagesCreatePage
  },
  {
    key: 'pages_edit',
    path: '/pages/:id/edit',
    component: PagesEditPage
  }
]
