import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { http } from '../../common/http/http.service'
import { EmailsImportRequestType, EmailsImportResponseType, EmailsQueryParamsType } from './emails.model'

const useEmailsList = (selectedPage: number, queryParams: EmailsQueryParamsType) => {
  const { page, pageSize, field, sort } = queryParams

  const { data, isFetching} = useQuery<any>(
    ['EMAILS_GET', page, pageSize, field, sort],
    async () => {
      const url = `/email/${selectedPage}/list?page=${page}&pageSize=${pageSize}&field=${field}&sort=${sort}`
      const { data } = await http().get(url)
      return data
    },
    {
      staleTime: Infinity,
      keepPreviousData: true
    }
  )

  return { data, isFetching }
}

const useEmailsImportMany = (
  hookOptions?: UseMutationOptions<
    EmailsImportResponseType,
    AxiosError,
    EmailsImportRequestType
  >
) => {
  return useMutation<
    EmailsImportResponseType,
    AxiosError,
    EmailsImportRequestType
  >(async (payload) => {
    const { data } = await http().post('/email/importMany', payload)
    return data
  }, hookOptions)
}

export {
  useEmailsList,
  useEmailsImportMany
}
