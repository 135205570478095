import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { PageContainer } from '../../../common/layout';
import { selectedPageState } from '../../pages';
import { createCampaignSchema } from '../campaigns.schema';
import { useCampaignsCreate } from '../campaigns.service';

export const CampaignsCreatePage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const selectedPage = useRecoilValue(selectedPageState)
  const { mutate, isLoading } = useCampaignsCreate()

  const onSuccess = () => {
    queryClient.invalidateQueries(['CAMPAIGNS_GET'])
    navigate('/campaigns')
  }

  const onSubmit = async (payload: any) => {
    try {
      mutate({...payload, pageId: selectedPage?.id}, { onSuccess })

    } catch (e) {
      console.log('ERR', e)
    }
  };

  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(createCampaignSchema)
  })


  return (
    <PageContainer header="Create campaign">
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        <TextField
          {...register("title")}
          margin="normal"
          required
          fullWidth
          label="Title"
          type="text"
          aria-autocomplete="none"
          autoComplete="off"
          error={errors?.title ? true : false}
          helperText={errors?.title?.message?.toString()}
          />
        <Button
          disabled={!isValid || isLoading}
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          >
          Add
        </Button>
        <Button
          variant="text"
          sx={{ mt: 2 }}
          onClick={() => navigate('/campaigns')}
          >
          Cancel
        </Button>
      </Box>
    </PageContainer>
  )
}