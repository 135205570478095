import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { GridColDef, GridSortModel } from '@mui/x-data-grid/models'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { formatDateTimestamp } from '../../../common/helpers'
import { FabComponent, PageContainer } from '../../../common/layout'
import { selectedPageState } from '../../pages'
import { StatusComponent } from '../components/StatusComponent'
import { useEmailsList } from '../emails.service'
import { emailsListTableQueryState } from '../emails.state'

export const EmailsIndexPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const selectedPage = useRecoilValue(selectedPageState)
  const [queryParams, setQueryParams] = useRecoilState(emailsListTableQueryState)
  const { data, isFetching } = useEmailsList(selectedPage?.id || 0, queryParams)

  const onRowClick = (e: any) => {
    navigate(`/emails/${e.id}`)
  }

  const onEditClick = (e: any, params: any) => {
    e.stopPropagation()
    navigate(`/emails/${params.id}/edit`)
  }

  const onPageSizeChange = async (pageSize: number) => {
    await setQueryParams({
      ...queryParams,
      pageSize,
    })
    queryClient.invalidateQueries(['EMAILS_GET'])
  }

  const onPageChange = (page: number) => {
    setQueryParams({
      ...queryParams,
        page
    })
    queryClient.invalidateQueries(['EMAILS_GET'])
  }

  const onSortChange = (model: GridSortModel) => {
    const { field, sort } = model[0] ? model[0] :
      { field: queryParams.field, sort: queryParams.sort === 'asc' ? 'desc' as 'desc' : 'asc' as 'asc' }

      setQueryParams({
      ...queryParams,
        page: 0,
        field,
        sort
    })
    queryClient.invalidateQueries(['EMAILS_GET'])
  }

  const columns: GridColDef[] = [
    { field: 'email', headerName: 'Email', flex: 1, disableColumnMenu: true },
    { field: 'status', headerName: 'Status', flex: 1, disableColumnMenu: true, sortable: false,
      renderCell: params => <StatusComponent status={params.value} />
    },
    { field: 'createdAt', headerName: 'Created at', flex: 1, disableColumnMenu: true, sortable: true,
      renderCell: params => formatDateTimestamp(params.value)
    },
    // {
    //   field: 'actions',
    //   headerName: '',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: params => <Button onClick={(e) => onEditClick(e, params)} variant="outlined" size="small">edit</Button>
    // }
  ]

  return (
    <PageContainer header="Emails">
      {data && <DataGrid
        autoHeight
        columns={columns}
        disableSelectionOnClick
        loading={isFetching}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowClick={onRowClick}
        onSortModelChange={onSortChange}
        page={data.page}
        pageSize={data.pageSize}
        paginationMode='server'
        rowCount={data.total}
        rows={data.result}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sortingMode='client'
        sortModel={[{ field: data?.field, sort: data?.sort }]}
        sx={{ cursor: 'pointer' }}
      />}
      <FabComponent onClick={() => navigate('/emails/import')} />
    </PageContainer>
  )
}