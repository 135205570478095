import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { PageContainer } from '../../../common/layout'
import { createPageSchema } from '../page.schema'
import { usePagesCreate } from '../pages.service'

export const PagesCreatePage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const { mutate, isLoading } = usePagesCreate()

  const onSuccess = () => {
    queryClient.invalidateQueries(['PAGES_GET'])
    navigate('/pages')
  }

  const onSubmit = async (payload: any) => {
    try {
      mutate(payload, { onSuccess })

    } catch (e) {
      console.log('ERR', e)
    }
  };

  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(createPageSchema)
  })

  return (
    <PageContainer header="Create page">
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        <TextField
          {...register("title")}
          margin="normal"
          required
          fullWidth
          label="Title"
          type="text"
          aria-autocomplete="none"
          autoComplete="off"
          error={errors?.title ? true : false}
          helperText={errors?.title?.message?.toString()}
          />
        <Button
          disabled={!isValid || isLoading}
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          >
          Add
        </Button>
        <Button
          variant="text"
          sx={{ mt: 2 }}
          onClick={() => navigate('/pages')}
          >
          Cancel
        </Button>
      </Box>
    </PageContainer>
  )
}
