import { Fragment } from 'react';
import { NavigateFunction } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Domain from '@mui/icons-material/Domain';
import ContactMail from '@mui/icons-material/ContactMail';
import Campaign from '@mui/icons-material/Campaign';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const mainMenuItems = (navigate: NavigateFunction) => (
  <Fragment>
    <ListItemButton onClick={() => navigate('/dashboard')}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('/pages')}>
      <ListItemIcon>
        <Domain />
      </ListItemIcon>
      <ListItemText primary="Pages" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('/emails')}>
      <ListItemIcon>
        <ContactMail />
      </ListItemIcon>
      <ListItemText primary="Emails" />
    </ListItemButton>
    <ListItemButton onClick={() => navigate('/campaigns')}>
      <ListItemIcon>
        <Campaign />
      </ListItemIcon>
      <ListItemText primary="Campaigns" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton>
  </Fragment>
);

export const secondaryMenuItems = (
  <Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </Fragment>
);