export type EmailsQueryParamsType = {
  pageSize: number
  page: number
  sort: 'asc' | 'desc' | null | undefined
  field: string
  total?: number
}

export enum EmailStatusEnum {
  CREATED = 'CREATED',
  IMPORTED = 'IMPORTED',
  DELETED = 'DELETED',
  VERIFIED = 'VERIFIED',
  SUSPENDED = 'SUSPENDED'
}

export type EmailsListResponseItemType = {
  id: number
  email: string
  status: EmailStatusEnum
  createdAt: Date
}

export type EmailsListResponseType = {
  items: EmailsListResponseItemType[]
}

export type EmailsImportRequestType = {
  email: string
}

export type EmailsImportResponseType = {
  id: number
}
