import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getToken, isTokenValid } from '../authentication';

// import { isLoggedInSelector } from '../../areas/auth/authentication.selectors';
// import { signInFromToken } from '../../areas/auth/authentication.slice';

export const RequireAuth = ({ children: Component }: any) => {
  // const dispatch = useDispatch();
  // const isLoggedIn = useSelector(isLoggedInSelector);
  // TOOD: verify token if expired
  // const lsTimestamp = localStorage.getItem('mTime');
  // const timestamp = moment.duration(moment().diff(moment(K_DAY))).asMilliseconds();
  // const diff = timestamp - lsTimestamp;
  // if (diff > SESSION_LIFETIME) {
  //   hashHistory.push('/konto/zaloguj');
  // } else {
  // TODO prepare auth payload
  // dispatch(signInFromToken(token));

  // const token = getToken()
  // const isTokenValid = !!token;
  // if (isTokenValid && !isLoggedIn) {
  //   dispatch(signInFromToken(token));
  // }

  return isTokenValid() ? Component : <Navigate to='/auth/signin' />;
};
