import { http } from '../../common/http/http.service'
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  PageQueryParamsType,
  PagesCreateRequestType,
  PagesCreateResponseType,
  PagesListRequestType,
  PagesListResponseType
} from '.'
import { AxiosError } from 'axios'

const usePagesList = (queryParams: PageQueryParamsType) => {
  const { page, pageSize, field, sort } = queryParams

  const { data, isFetching} = useQuery<any>(
    ['PAGES_GET', page, pageSize, field, sort],
    async () => {
      const url = `/page?page=${page}&pageSize=${pageSize}&field=${field}&sort=${sort}`
      const { data } = await http().get(url)
      return data
    },
    {
      staleTime: Infinity,
      keepPreviousData: true
    }
  )

  return { data, isFetching }
}

const usePage = (id: number) => {
  const { data, isFetching} = useQuery<any>(
    ['PAGE_GET', id],
    async () => {
      const { data } = await http().get(`/page/${id}`)
      return data
    },
    {
      staleTime: Infinity
    }
  )

  return { data, isFetching }
}

const usePagesCreate = (
  hookOptions?: UseMutationOptions<
    PagesCreateResponseType,
    AxiosError,
    PagesCreateRequestType
  >
) => {
  return useMutation<
    PagesCreateResponseType,
    AxiosError,
    PagesCreateRequestType
  >(async (payload) => {
    const { data } = await http().post('/page', payload)
    return data
  }, hookOptions)
}

const usePagesEdit = (
  id: number,
  hookOptions?: UseMutationOptions<
    PagesCreateResponseType,
    AxiosError,
    PagesCreateRequestType
  >
) => {
  return useMutation<
    PagesCreateResponseType,
    AxiosError,
    PagesCreateRequestType
  >(async (payload) => {
    const { data } = await http().put(`/page/${id}`, payload)
    return data
  }, hookOptions)
}

const usePagesDelete = (
  id: number,
  hookOptions?: UseMutationOptions<
    any,
    AxiosError,
    any
  >
) => {
  return useMutation<
    any,
    AxiosError,
    any
  >(async () => {
    const { data } = await http().delete(`/page/${id}`)
    return data
  }, hookOptions)
}

export {
  usePagesList,
  usePage,
  usePagesCreate,
  usePagesEdit,
  usePagesDelete
}
