import * as yup from 'yup';

export const signInSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
}).required();

export const signUpSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required().min(8),
}).required();

