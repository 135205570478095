import styled from '@emotion/styled'

const DetailsContainerStyles = styled.div`
  width: 100%;

  & > div {
    display: flex;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 2px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 1rem;
    margin-bottom: 2px;
    & > div:nth-of-type(1) {
      width: 30%;
      font-weight: bold;
      text-transform: uppercase;
      opacity: 0.5;
    }
  }
`

export const DetailsContainer = ({ children }: any) => {
  return (
    <DetailsContainerStyles>
      {children}
    </DetailsContainerStyles>
  )
}
