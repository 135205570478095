import { SignInPage } from './pages/SignInPage';
import { SignUpPage } from './pages/SignUpPage';

export const authRoutes = [
  {
    key: 'auth_signup',
    path: '/auth/signup',
    component: SignUpPage
  },
  {
    key: 'auth_signin',
    path: '/auth/signin',
    component: SignInPage
  }
]
