import * as yup from 'yup'

export const importEmailSchema = yup.object({
  email: yup.string().email().max(100).required(),
}).required()

export const importManyEmailsSchema = yup.object({
  emails: yup.array().transform((value, originalValue) => originalValue.split(';')).of(yup.string().email()).min(1),
}).required()

// export const importManyEmailsSchema = yup.object().shape({
//   emails: yup.array()
//     .transform(function(value, originalValue) {
//       if (this.isType(value) && value !== null) {
//         return value;
//       }
//       return originalValue ? originalValue.split(/[\s,]+/) : [];
//     }).required()
//     .of(yup.string().email(({ value }) => `${value} is not a valid email`)),
// })
