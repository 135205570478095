import { atom } from 'recoil';
import { EmailsQueryParamsType } from './emails.model';

export const emailsListTableQueryState = atom<EmailsQueryParamsType>({
  key: 'emailsListTableQueryState',
  default: {
    pageSize: 10,
    sort: 'asc',
    page: 0,
    field: 'createdAt'
  }
})