import Button from '@mui/material/Button'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { setLocalStorageSelectedPage } from '../../../common/helpers'
import { ButtonsContainer, DetailsContainer, PageContainer } from '../../../common/layout'
import { usePage, usePagesDelete } from '../pages.service'
import { useConfirm } from 'material-ui-confirm'
import { selectedPageState } from '../'

export const PagesDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const confirm = useConfirm()
  // const selectedPage = useRecoilValue(selectedPageState)
  const [selectedPage, setSelectedPage] = useRecoilState(selectedPageState)

  const id = Number(params?.id) || 0
  const { data, isFetching } = usePage(id)
  const { mutate } = usePagesDelete(id)
  const queryClient = useQueryClient()

  const isCurrentPageSelected = () => selectedPage?.id === id

  const selectedPageToggle = () => {
    confirm({
      title: 'Confirm operation',
      description: isCurrentPageSelected() ? `Page "${data.title}" will be unset of default` :
        `Page "${data.title}" will be set as default,\r\nand all other operations will be done in its context.`
    })
      .then(() => {
        //TODO: service to set selected and invalidate proper queries
        setSelectedPage(isCurrentPageSelected() ? null : data)
        setLocalStorageSelectedPage(isCurrentPageSelected() ? null : data)
        queryClient.invalidateQueries(['PAGES_GET'])
        queryClient.invalidateQueries(['CAMPAIGNS_GET'])
      }).catch(() => void(0))
  }

  const onSuccess = () => {
    queryClient.invalidateQueries(['PAGES_GET'])
    navigate('/pages')
  }

  const onDeleteClick = () => {
    confirm({
      title: 'Confirm delete',
      description: `This operation cannot be undone.\r\nPage "${data.title}" will be permanently deleted.`
    })
      .then(() => {
        try {
          mutate({}, { onSuccess })
        } catch (e) {
          console.log('ERR', e)
        }
      }).catch(() => void(0))
  }

  return (
    <PageContainer header="Page details">
      {isFetching && <p>Loading</p>}
      {!isFetching && data &&
        <>
          <DetailsContainer>
            <div>
              <div>Title</div>
              <div>{data.title}</div>
            </div>
            <div>
              <div>Prompt text</div>
              <div>{data.promptText}</div>
            </div>
            <div>
              <div>Identifier</div>
              <div>{data.guid}</div>
            </div>
            <div>
              <div>Endpoint</div>
              <div>{process.env.REACT_APP_ENDPOINT_URL}/?{data.guid}</div>
            </div>
          </DetailsContainer>
          <ButtonsContainer>
            <Button onClick={() => navigate('/pages')}>back</Button>
            <Button onClick={() => navigate(`/pages/${data.id}/edit`)}>edit</Button>
            <Button onClick={onDeleteClick} variant="outlined" >delete</Button>
            <Button onClick={selectedPageToggle} variant={isCurrentPageSelected() ? 'contained' : 'outlined'}>{
              isCurrentPageSelected() ? 'This page is selected' : 'Set as selected'
            }</Button>
          </ButtonsContainer>
        </>
      }

    </PageContainer>
  )
}
