import { EmailsImportPage } from './pages/EmailsImportPage';
import { EmailsIndexPage } from './pages/EmailsIndexPage';

export const emailsRoutes = [
  {
    key: 'emails',
    path: '/emails',
    component: EmailsIndexPage
  },
  {
    key: 'emails_import',
    path: '/emails/import',
    component: EmailsImportPage
  }
]