import axios from 'axios';
import { ApiUrl } from '../config';
import { errorHandler, successHandler } from './http.interceptors';

const http = () => {
  const token = localStorage.getItem('mToken');
  const authorization = token ? { Authorization: `Bearer ${token}` } : undefined;
  const params = {
    headers: {
      'Content-Type': 'application/json',
      ...authorization,
    },
  };

  const instance = axios.create(params);

  instance.interceptors.request.use(config => {
    config.baseURL = ApiUrl();
    return config;
  });

  instance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  );

  return instance;
};

export { http };
