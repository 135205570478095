import { CampaignsCreatePage } from './pages/CampaignsCreatePage';
import { CampaignsDetailsPage } from './pages/CampaignsDetailsPage';
import { CampaignsEditPage } from './pages/CampaignsEditPage';
import { CampaignsIndexPage } from './pages/CampaignsIndexPage';

export const campaignsRoutes = [
  {
    key: 'campaigns',
    path: '/campaigns',
    component: CampaignsIndexPage
  },
  {
    key: 'campaigns_id',
    path: '/campaigns/:id',
    component: CampaignsDetailsPage
  },
  {
    key: 'campaigns_add',
    path: '/campaigns/add',
    component: CampaignsCreatePage
  },
  {
    key: 'campaigns_edit',
    path: '/campaigns/:id/edit',
    component: CampaignsEditPage
  }
]