import Add from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'

export const FabComponent = ({ onClick }: any) => {
  return (
    <Fab
      color="primary"
      aria-label="add"
      sx={{ position: 'absolute', bottom: '2rem', right: '2rem' }}
      onClick={onClick}
      >
      <Add />
    </Fab>
  )
}
