import { EmailStatusEnum } from '../emails.model';
import Chip from '@mui/material/Chip'

export const StatusComponent = ({ status }: { status: EmailStatusEnum }) => {
  switch (status) {
    case EmailStatusEnum.CREATED:
      return <Chip size='small' label='Created' color='default' />
    case EmailStatusEnum.IMPORTED:
      return <Chip size='small' label='Imported' color='default' />
    case EmailStatusEnum.VERIFIED:
      return <Chip size='small' label='Verified' color='default' />
    case EmailStatusEnum.DELETED:
      return <Chip size='small' label='Deleted' color='error' />
    case EmailStatusEnum.SUSPENDED:
      return <Chip size='small' label='Suspended' color='warning' />
    default:
      throw new Error('unknown')
  }
}
