import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { authRoutes } from './areas/auth';
import { DefaultContainer } from './common/layout';
import { RequireAuth, routes } from './common/routing';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route key="path_" path="/" element={<Navigate to="/dashboard" />} />
        {authRoutes.map((route: any) => {
          const Component = route.component

          return <Route
            key={route.key}
            path={route.path}
            element={<Component />}
          />
        })}
        {routes.map((route: any) => {
          const Component = route.component

          return <Route
              key={route.key}
              path={route.path}
              element={<RequireAuth>
                <DefaultContainer>
                  <Component />
                </DefaultContainer>
              </RequireAuth>}
            />
        })}
      </Routes>

    </BrowserRouter>

  )
}
