import Paper from '@mui/material/Paper'
import styled from 'styled-components'

const PageContainerStyles = styled.div`
  padding: 0 2rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 300;
  }

  .header {

  }
  .content {
    display: flex;
    flex-direction: column;
    /* margin: 0 auto; */
    max-width: 1200px;
    border-radius: 2px;
    box-shadow: 0px 2px 30px -1px rgb(0 0 0 / 5%), 0px 1px 30px 0px rgb(0 0 0 / 5%), 0px 1px 30px 0px rgb(0 0 0 / 4%);
    padding: 2rem;

    form {
      margin: 0;
      width: 100%;

      button {
        margin-right: 0.25rem;
      }
    }
  }
`

export const PageContainer = ({ children, header }: any) => {
  return (
    <PageContainerStyles>
      <div className="header">
        <h1>{header}</h1>
      </div>
      <Paper className="content" elevation={1}>
        {children}
      </Paper>
    </PageContainerStyles>
  )
}
