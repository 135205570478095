import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { GridColDef, GridSortModel } from '@mui/x-data-grid/models'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import { FabComponent, PageContainer } from '../../../common/layout'
import { usePagesList } from '../pages.service'
import { pagesListTableQueryState, selectedPageState } from '../'

export const PagesIndexPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const selectedPage = useRecoilValue(selectedPageState)
  const [queryParams, setQueryParams] = useRecoilState(pagesListTableQueryState)
  const { data, isFetching } = usePagesList(queryParams)

  const onRowClick = (e: any) => {
    navigate(`/pages/${e.id}`)
  }

  const onEditClick = (e: any, params: any) => {
    e.stopPropagation()
    navigate(`/pages/${params.id}/edit`)
  }

  const onPageSizeChange = async (pageSize: number) => {
    await setQueryParams({
      ...queryParams,
      pageSize,
    })
    queryClient.invalidateQueries(['PAGES_GET'])
  }

  const onPageChange = (page: number) => {
    setQueryParams({
      ...queryParams,
        page
    })
    queryClient.invalidateQueries(['PAGES_GET'])
  }

  const onSortChange = (model: GridSortModel) => {
    const { field, sort } = model[0] ? model[0] :
      { field: queryParams.field, sort: queryParams.sort === 'asc' ? 'desc' as 'desc' : 'asc' as 'asc' }

      setQueryParams({
      ...queryParams,
        page: 0,
        field,
        sort
    })
    queryClient.invalidateQueries(['PAGES_GET'])
  }

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1, disableColumnMenu: true },
    { field: 'guid', headerName: 'Identifier', flex: 1, disableColumnMenu: true, sortable: false },
    { field: 'id', headerName: 'Selected', flex: 1, disableColumnMenu: true, sortable: false,
      renderCell: params => selectedPage?.id === params?.row?.id && <Chip sx={{ ml: 3}} label="SELECTED" color="primary" />
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => <Button onClick={(e) => onEditClick(e, params)} variant="outlined" size="small">edit</Button>
    }
  ]

  return (
    <PageContainer header="Pages">
      {!selectedPage && <Alert sx={{ mb: '1rem' }} severity="warning">No page is selected as current!</Alert>}
      {data && <DataGrid
        autoHeight
        columns={columns}
        disableSelectionOnClick
        loading={isFetching}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onRowClick={onRowClick}
        onSortModelChange={onSortChange}
        page={data.page}
        pageSize={data.pageSize}
        paginationMode='server'
        rowCount={data.total}
        rows={data.result}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sortingMode='client'
        sortModel={[{ field: data?.field, sort: data?.sort }]}
        sx={{ cursor: 'pointer' }}
      />}
      <FabComponent onClick={() => navigate('/pages/add')} />
    </PageContainer>
  )
}
