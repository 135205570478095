import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { PageContainer } from '../../../common/layout'
import { editCampaignSchema } from '../campaigns.schema'
import { useCampaign, useCampaignsEdit } from '../campaigns.service'

export const CampaignsEditPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const queryClient = useQueryClient()
  const id = Number(params?.id) || 0

  const { data, isFetching } = useCampaign(id)
  const { mutate, isLoading } = useCampaignsEdit(id)

  const onSuccess = () => {
    queryClient.invalidateQueries(['CAMPAIGN_GET', id])
    queryClient.invalidateQueries(['CAMPAIGNS_GET'])
    navigate('/campaigns')
  }

  const onSubmit = async (payload: any) => {
    try {
      mutate(payload, { onSuccess })
    } catch (e) {
      console.log('ERR', e)
    }
  }

  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(editCampaignSchema)
  })

  useEffect(() => {
    if (data) {
      const { title } = data
      reset({ title })
    }
  }, [data, reset])


  return (
    <PageContainer header="Campaign edit">
      {isFetching && <p>Loading</p>}
      {!isLoading && data && (
        <>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
            <TextField
              {...register("title")}
              margin="normal"
              required
              fullWidth
              label="Title"
              type="text"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.title ? true : false}
              helperText={errors?.title?.message?.toString()}
            />
            <Button
              disabled={!isValid || isLoading}
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              >
              Save
            </Button>
            <Button
              variant="text"
              sx={{ mt: 2 }}
              onClick={() => navigate('/campaign')}
              >
              Cancel
            </Button>
          </Box>
        </>
      )}
    </PageContainer>
  )
}