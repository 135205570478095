import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContainer } from '../components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { StyledLink } from '../../../common/layout';
import { signInSchema } from '../';
import { useSignIn } from '../auth.service';
import { setToken } from '../../../common/authentication';

export const SignInPage = () => {
  const navigate = useNavigate()
  const { mutate, isLoading, isError, data, isSuccess } = useSignIn()

  const onSubmit = async (payload: any) => {
    try {
      mutate(payload)

    } catch (e) {
      console.log('ERR', e)
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setToken(data.token)
      navigate('/')
    }
  }, [data, isSuccess, navigate])

  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: "onBlur",
    resolver: yupResolver(signInSchema)
  });

  return (
    <AuthContainer title="Sign in">
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        <TextField
          {...register("email")}
          margin="normal"
          required
          fullWidth
          label="Email"
          type="text"
          aria-autocomplete="none"
          autoComplete="off"
          error={errors?.email ? true : false}
          helperText={errors?.email?.message?.toString()}
        />
        <TextField
          {...register("password")}
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          aria-autocomplete="none"
          autoComplete="off"
          error={errors?.password ? true : false}
          helperText={errors?.password?.message?.toString()}
        />

        {isError && (
          <Alert severity="error">
            <AlertTitle>Ooops</AlertTitle>
            This email or password is incorrect
          </Alert>
        )}
        <Button
          disabled={!isValid || isLoading}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <StyledLink to="/auth/reset">
              Forgot password?
            </StyledLink>
          </Grid>
          <Grid item>
            <StyledLink to="/auth/signup">
              {"Don't have an account? Sign Up"}
            </StyledLink>
          </Grid>
        </Grid>
      </Box>
    </AuthContainer>
  );
}
