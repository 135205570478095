import { yupResolver } from '@hookform/resolvers/yup';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { PageContainer } from '../../../common/layout';
import { selectedPageState } from '../../pages';
import { importManyEmailsSchema } from '../emails.schema';
import { useEmailsImportMany } from '../emails.service';

export const EmailsImportPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const selectedPage = useRecoilValue(selectedPageState)
  const { mutate, isLoading, error } = useEmailsImportMany()
  const [ emails, setEmails ] = useState([])

  const onSuccess = () => {
    queryClient.invalidateQueries(['EMAILS_GET'])
    navigate('/emails')
  }

  const onSubmit = async (payload: any) => {
    try {
      // console.log(payload)
      mutate({...payload, pageGuid: selectedPage?.guid}, { onSuccess })

    } catch (e) {
      console.log('ERR', e)
    }
  };

  const { register, handleSubmit, getValues, formState: { errors, isValid } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(importManyEmailsSchema)
  })

  return (
    <PageContainer header="Import email/emails">
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        <TextField
          {...register("emails")}
          multiline
          rows={10}
          margin="normal"
          required
          fullWidth
          label="Email(s)"
          type="text"
          aria-autocomplete="none"
          autoComplete="off"
          error={errors?.email ? true : false}
          helperText={errors?.email?.message?.toString()}
        />
        {error && <Alert sx={{ mt: '0.5rem', mb: '0.5rem' }} severity="warning">This email is already added for the current page</Alert>}
        <Button
          disabled={!isValid || isLoading || !getValues().emails }
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          >
          Import
        </Button>
        <Button
          variant="text"
          sx={{ mt: 2 }}
          onClick={() => navigate('/emails')}
          >
          Cancel
        </Button>
      </Box>

    </PageContainer>
  )
}