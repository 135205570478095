import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { PageContainer } from '../../../common/layout'
import { createPageSchema } from '../page.schema'
import { usePage, usePagesEdit } from '../pages.service'

export const PagesEditPage = (props: any) => {
  const navigate = useNavigate()
  const params = useParams()
  const queryClient = useQueryClient()
  const id = Number(params?.id) || 0

  const { data, isFetching } = usePage(id)
  const { mutate, isLoading } = usePagesEdit(id)

  const onSuccess = () => {
    queryClient.invalidateQueries(['PAGE_GET', id])
    queryClient.invalidateQueries(['PAGES_GET'])
    navigate('/pages')
  }

  const onSubmit = async (payload: any) => {
    try {
      mutate(payload, { onSuccess })
    } catch (e) {
      console.log('ERR', e)
    }
  }

  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(createPageSchema)
  })

  useEffect(() => {
    if (data) {
      reset({ title: data.title })
    }
  }, [data, reset])

  return (
    <>
      <PageContainer header="Page edit">
        {isFetching && <p>Loading</p>}
        {!isLoading && data && (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
              <TextField
                {...register("title")}
                margin="normal"
                required
                fullWidth
                label="Title"
                type="text"
                aria-autocomplete="none"
                autoComplete="off"
                error={errors?.title ? true : false}
                helperText={errors?.title?.message?.toString()}
              />
              <TextField
                {...register("promptText")}
                margin="normal"
                required
                fullWidth
                label="Prompt text"
                type="text"
                aria-autocomplete="none"
                autoComplete="off"
                error={errors?.promptText ? true : false}
                helperText={errors?.promptText?.message?.toString()}
              />
              <Button
                disabled={!isValid || isLoading}
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                >
                Save
              </Button>
              <Button
                variant="text"
                sx={{ mt: 2 }}
                onClick={() => navigate('/pages')}
                >
                Cancel
              </Button>
            </Box>
          </>
        )}
      </PageContainer>
    </>
  )
}
