import { atom } from 'recoil';
import { CampaignsQueryParamsType } from './campaigns.model';

export const campaignsListTableQueryState = atom<CampaignsQueryParamsType>({
  key: 'campaignsListTableQueryState',
  default: {
    pageSize: 10,
    sort: 'desc',
    page: 0,
    field: 'createdAt'
  }
})