import { http } from '../../common/http/http.service'
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  CampaignsQueryParamsType,
  CampaignsCreateRequestType,
  CampaignsCreateResponseType,
  // CampaignsListRequestType,
  CampaignsListResponseType
} from './campaigns.model'
import { AxiosError } from 'axios'

const useCampaignsList = (selectedPage: number, queryParams: CampaignsQueryParamsType) => {
  const { page, pageSize, field, sort } = queryParams

  const { data, isFetching} = useQuery<any>(
    ['CAMPAIGNS_GET', page, pageSize, field, sort],
    async () => {
      const url = `/campaign/${selectedPage}/list?page=${page}&pageSize=${pageSize}&field=${field}&sort=${sort}`
      const { data } = await http().get(url)
      return data
    },
    {
      staleTime: Infinity,
      keepPreviousData: true
    }
  )

  return { data, isFetching }
}

const useCampaign = (id: number) => {
  const { data, isFetching} = useQuery<any>(
    ['CAMPAIGN_GET', id],
    async () => {
      const { data } = await http().get(`/campaign/${id}`)
      return data
    },
    {
      staleTime: Infinity
    }
  )

  return { data, isFetching }
}

const useCampaignsCreate = (
  hookOptions?: UseMutationOptions<
    CampaignsCreateResponseType,
    AxiosError,
    CampaignsCreateRequestType
  >
) => {
  return useMutation<
    CampaignsCreateResponseType,
    AxiosError,
    CampaignsCreateRequestType
  >(async (payload) => {
    const { data } = await http().post('/campaign', payload)
    return data
  }, hookOptions)
}

const useCampaignsEdit = (
  id: number,
  hookOptions?: UseMutationOptions<
    CampaignsCreateResponseType,
    AxiosError,
    CampaignsCreateRequestType
  >
) => {
  return useMutation<
    CampaignsCreateResponseType,
    AxiosError,
    CampaignsCreateRequestType
  >(async (payload) => {
    const { data } = await http().put(`/campaign/${id}`, payload)
    return data
  }, hookOptions)
}

const useCampaignsDelete = (
  id: number,
  hookOptions?: UseMutationOptions<
    any,
    AxiosError,
    any
  >
) => {
  return useMutation<
    any,
    AxiosError,
    any
  >(async () => {
    const { data } = await http().delete(`/campaign/${id}`)
    return data
  }, hookOptions)
}

export {
  useCampaignsList,
  useCampaign,
  useCampaignsCreate,
  useCampaignsEdit,
  useCampaignsDelete
}
