import { Link, Typography } from '@mui/material'
import styled from 'styled-components'

const CopyrightContainer = styled.div`
  margin-bottom: 32px;

  a {
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`

export const Copyright = (props: any) => {
  return (
    <CopyrightContainer>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="/">Majler.pl</Link>{' '}
        2012-{new Date().getFullYear()}
      </Typography>
    </CopyrightContainer>
  );
}