import Button from '@mui/material/Button'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useConfirm } from 'material-ui-confirm'
import { ButtonsContainer, DetailsContainer, PageContainer } from '../../../common/layout'
import { selectedPageState } from '../../pages'
import { useCampaign, useCampaignsDelete } from '../campaigns.service'
import { StatusComponent } from '../components/StatusComponent'

export const CampaignsDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const confirm = useConfirm()
  const selectedPage = useRecoilValue(selectedPageState)

  const id = Number(params?.id) || 0
  const { data, isFetching } = useCampaign(id)
  const { mutate } = useCampaignsDelete(id)
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries(['CAMPAIGNS_GET'])
    navigate('/campaigns')
  }

  const onDeleteClick = () => {
    confirm({
      title: 'Confirm delete',
      description: `This operation cannot be undone.\r\nCampaign "${data.title}" will be permanently deleted.`
    })
      .then(() => {
        try {
          mutate({}, { onSuccess })
        } catch (e) {
          console.log('ERR', e)
        }
      }).catch(() => void(0))
  }

  return (
    <PageContainer header="Campaign details">
      {isFetching && <p>Loading</p>}
      {!isFetching && data &&
        <>
          <DetailsContainer>
            <div>
              <div>Title</div>
              <div>{data.title}</div>
            </div>
            <div>
              <div>Identifier</div>
              <div>{data.guid}</div>
            </div>
            <div>
              <div>Status</div>
              <div><StatusComponent status={data.status} /></div>
            </div>
          </DetailsContainer>
          <ButtonsContainer>
            <Button onClick={() => navigate('/campaigns')}>back</Button>
            <Button onClick={() => navigate(`/campaigns/${data.id}/edit`)}>edit</Button>
            <Button onClick={onDeleteClick} variant="outlined" >delete</Button>
          </ButtonsContainer>
        </>
      }

    </PageContainer>
  )
}
