import { atom } from 'recoil';
import { PageQueryParamsType, PagesListResponseItemType } from './pages.model';

export const selectedPageState = atom<PagesListResponseItemType | null>({
  key: 'selectedPageState',
  default: null
})

export const pagesListTableQueryState = atom<PageQueryParamsType>({
  key: 'pagesListTableQueryState',
  default: {
    pageSize: 10,
    sort: 'asc',
    page: 0,
    field: 'title'
  }
})
