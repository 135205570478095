import { authRoutes } from '../../areas/auth/'
import { campaignsRoutes } from '../../areas/campaigns'
import { dashboardRoutes } from '../../areas/dashboard'
import { emailsRoutes } from '../../areas/emails'
import { endpointRoutes } from '../../areas/endpoint'
import { pagesRoutes } from '../../areas/pages'

export const routes = [
  // ...authRoutes,
  ...dashboardRoutes,
  ...pagesRoutes,
  ...campaignsRoutes,
  ...emailsRoutes,
  ...endpointRoutes
]
