import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { getLocalStorageSelectedPage } from '../../../common/helpers'
import { PageContainer } from '../../../common/layout'
import { selectedPageState } from '../../pages'

export const DashboardIndexPage = () => {
  const [_, setSelectedPage] = useRecoilState(selectedPageState)

  useEffect(() => {
    const page = getLocalStorageSelectedPage()
    if (page) {
      setSelectedPage(page)
    }
  }, [setSelectedPage])

  return (
    <PageContainer header="Dashboard">
      <p>Lorem ipsum dolor sit amet</p>
    </PageContainer>
  )
}
