import { Box, createTheme, CssBaseline, Grid, Paper, ThemeProvider, Typography } from '@mui/material';
import { Copyright } from './Copyright';

const theme = createTheme();

export const AuthContainer = ({ children, title }: any) => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: 100 }}>
              <img src="/yellow_mail.svg" alt="" />
            </Box>
            <Typography component="h1" variant="h5">{title}</Typography>
            {children}
          </Box>
          <Copyright />
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
