import { http } from '../../common/http/http.service'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { SignInRequestType, SignInResponseType } from '.'
import { AxiosError } from 'axios'

const useSignIn = (
  hookOptions?: UseMutationOptions<
    SignInResponseType,
    AxiosError,
    SignInRequestType
  >
) => {
  return useMutation<
    SignInResponseType,
    AxiosError,
    SignInRequestType
  >(async (payload) => {
    const { data } = await http().post('/user/signin', payload)
    return data
  }, hookOptions)
}

export { useSignIn }
