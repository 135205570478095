import { AxiosResponse } from 'axios';
import { HttpError, HttpCode } from './http.model';

const successHandler = (response: AxiosResponse<any>): AxiosResponse<any> => {
  return response;
};

// const errorHandler = (error: HttpError): Promise<never> => {
const errorHandler = (error: HttpError) => {
  if (error) {
    // if (!error.response) {
    //   window.location.href = '/error';
    // }
    if (error.response.status === HttpCode.UNAUTHORIZED) {
      console.log('UNAUTHORIZED');
      // localStorage.removeItem('mToken');
      if (window.location.pathname !== '/auth/signin') {
        window.location.href = '/auth/signin';
      }
      // return null;
    }

    console.log('other error');
  }

  return Promise.reject({ ...error });
};

export { errorHandler, successHandler };
