import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Styled = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s;

  &:hover {
    color: rgba(0, 0, 0, 0.87);
  }
`

export const StyledLink = (props: any) => <Styled {...props} />
